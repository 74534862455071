<template>
  <de-progress-spinner v-if="isRecaptchaLoading" class="tw-mt-10 tw-w-1000 tw-h-1000" />
  <div
    v-show="!isRecaptchaLoading"
    ref="gRecaptcha"
    class="tw-flex tw-justify-center tw-mt-10"
  ></div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRuntimeConfig } from '#app';
import { G_RECAPTCHA_API_URL } from '~/shared/constants/recaptcha.constant';
import DeProgressSpinner from '~/shared/components/lib/progress/DeProgressSpinner.vue';

const isRecaptchaLoading = ref(true);
const gRecaptcha = ref<HTMLElement | null>(null);
const config = useRuntimeConfig();

const onCaptchaFullyRendered = () => {
  isRecaptchaLoading.value = false;
};

const onCaptchaIframeLoaded = () => {
  const iframe = document.querySelector("iframe[title='reCAPTCHA']");

  if (iframe) {
    iframe.addEventListener('load', onCaptchaFullyRendered, true);
  }
};

const emit = defineEmits<{
  verifyCallback: [token: string];
}>();

function renderCaptcha() {
  if (!gRecaptcha.value) return;

  window.grecaptcha?.render(gRecaptcha.value, {
    sitekey: config.public.G_RECAPTCHA_SITE_KEY,
    theme: 'dark',
    callback: (token: string) => {
      emit('verifyCallback', token);
    },
  });

  onCaptchaIframeLoaded();
}

function loadRecaptchaScript() {
  if (window.grecaptcha) {
    renderCaptcha();

    return;
  }

  const script = document.createElement('script');
  script.src = `${G_RECAPTCHA_API_URL}?onload=gRecaptchaOnloadCallback&render=explicit`;
  script.async = true;
  script.defer = true;

  document.head.append(script);

  window.gRecaptchaOnloadCallback = function () {
    renderCaptcha();
  };
}

onMounted(() => {
  loadRecaptchaScript();
});
</script>
