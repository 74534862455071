import { ref } from 'vue';

export const useRecaptcha = () => {
  const captchaResponseToken = ref<string | null>(null);

  function onVerifyCallback(token: string) {
    captchaResponseToken.value = token;
  }

  return {
    captchaResponseToken,
    onVerifyCallback,
  };
};
